<template>
  <div class="users">
    <b-button @click="modalShow = !modalShow">{{
      $t("add_user.add_user")
    }}</b-button>
    <b-modal id="modal-lg" v-model="modalShow" size="lg" hide-footer>
      <form @submit.prevent="addUser()">
        <div>
          <label for="">{{ $t("add_user.firstname") }}</label>
          <b-form-input v-model="form.firstName"></b-form-input>
        </div>
        <div>
          <label for="">{{ $t("add_user.lastname") }}</label>
          <b-form-input v-model="form.lastName"></b-form-input>
        </div>
        <div>
          <label for="">{{ $t("add_user.fullname") }}</label>
          <b-form-input v-model="form.fullName"></b-form-input>
        </div>
        <div>
          <label for="">{{ $t("admin.email") }}</label>
          <input type="email" v-model="form.email" required />
        </div>
        <div class="input">
          <label for="">{{ $t("admin.password") }}</label>
          <input :type="passtype" v-model="form.password" />
          <i :class="show_pass" @click="showPassword"></i>
        </div>
        <div>
          <label for="">{{ $t("add_user.role") }}</label>
          <b-form-select
            v-model="form.role"
            :options="userRoles"
          ></b-form-select>
        </div>
        <div>
          <label for="">{{ $t("add_user.permission") }}</label>
          <multiselect
            v-model="selected"
            :placeholder="$t('add_user.permission')"
            label="permission"
            track-by="permission"
            :options="allPermission"
            :multiple="true"
            :taggable="true"
          ></multiselect>
        </div>
        <button type="submit" class="submit btn btn-primary">
          <span v-show="!isLoading">{{ $t("admin.add") }}</span>
          <span v-show="isLoading">
            {{ $t("login.loading") }}
            <spinner v-show="isLoading" text="loading" />
          </span>
        </button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
import { VueEditor } from "vue2-editor";
import Multiselect from "vue-multiselect";
import spinner from "../../spinner/Spinner.vue";
import Swal from "sweetalert2";

export default {
  components: {
    VueEditor,
    Multiselect: VueMultiselect.default,
    spinner,
  },
  name: "login",
  data() {
    return {
      modalShow: false,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        fullName: "",
        password: "",
        role: null,
        permissions: [],
      },
      selected: "",
      allPermission: [],
      userRoles: [
        { value: null, text: this.$t("add_user.choose_role") },
        { value: "moderator", text: "moderator" },
        { value: "owner", text: "owner" },
      ],
      passtype: "password",
      show_pass: "far fa-eye-slash",
      isLoading: false,
    };
  },
  props: {
    getUser: { type: Function },
  },
  mounted() {
    this.getPermissions();
  },
  methods: {
    showPassword() {
      if (this.passtype === "password") {
        this.passtype = "text";
        this.show_pass = "far fa-eye";
      } else {
        this.passtype = "password";
        this.show_pass = "far fa-eye-slash";
      }
    },
    getPermissions() {
      axios.get(`${env.host}/get/permissions`).then((res) => {
        this.allPermission = res.data.item;
      });
    },
    addUser() {
      this.isLoading = true;
      this.selected.map((item) => {
        this.form.permissions.push(item._id);
      });
      axios
        .post(`${env.host}/add/user`, this.form)
        .then(() => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_add"),
          });
          this.isLoading = false;
          this.modalShow = false;
          this.emptyValues();
          setTimeout(() => {
            this.getUser();
          }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    emptyValues() {
      this.form.firstName = "";
      this.form.lastName = "";
      this.form.email = "";
      this.form.fullName = "";
      this.form.password = "";
      this.form.role = null;
      this.selected = "";
    },
  },
};
</script>

<style scoped>
label {
  font-family: mtavruliBOLD;
}
button {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
.users {
  margin-bottom: 10px;
}
.text_editor {
  margin-bottom: 50px;
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;

  cursor: pointer;
  outline: none;
  color: white;
}
form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.input {
  position: relative !important;
}
input {
  width: 100%;
  height: 50px;
  border-radius: 0px;
  border: 1px solid #dadada;
  outline: none;
  padding-left: 20px;
}
input:focus {
  border: 1px solid #7e5493;
  transition: 0.3s;
}
.input i {
  position: absolute !important;
  right: 15px;
  top: 48px;
  cursor: pointer;
}
::v-deep .multiselect__option--highlight,
::v-deep .multiselect__option--highlight:after {
  background: #7e5493 !important;
}
</style>
