<template>
  <div class="user_management" style="padding: 5px 10px 10px 10px">
    <AddUser :getUser="getUser" />
    <div v-if="!isLoading" class="testingScroll">
      <table>
        <tr>
          <th>{{ $t("add_user.firstname") }}</th>
          <th>{{ $t("add_user.lastname") }}</th>
          <th>{{ $t("admin.email") }}</th>
          <th>{{ $t("add_user.fullname") }}</th>
          <th>{{ $t("add_user.role") }}</th>
          <th>{{ $t("add_user.permission") }}</th>
          <th>{{ $t("admin.password") }}</th>
          <th>{{ $t("admin.edit") }}</th>
          <th>{{ $t("admin.delete") }}</th>
        </tr>
        <tr v-for="user in allUser" :key="user.id">
          <td>
            {{ user.firstName }}
          </td>
          <td>
            {{ user.lastName }}
          </td>
          <td>
            {{ user.email }}
          </td>
          <td>
            {{ user.fullName }}
          </td>
          <td>
            {{ user.role }}
          </td>
          <td>
            <div v-for="item in user.permissions" :key="item.id">
              {{ item.permission }}
            </div>
          </td>
          <td>
            {{ user.password }}
          </td>
          <td>
            <b-button
              @click="
                (modalShow = !modalShow),
                  editUser(
                    user._id,
                    user.firstName,
                    user.lastName,
                    user.email,
                    user.fullName,
                    user.role,
                    user.password
                  )
              "
            >
              <i class="fal fa-pen"></i>
            </b-button>
          </td>
          <td>
            <ConfirmDelete @clicktodelete="deleteUser(user._id)" />
          </td>
        </tr>
      </table>
    </div>
    <div v-else>
      <b-skeleton-table
        :rows="3"
        :columns="9"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
    </div>

    <b-modal
      id="modal-lg-2"
      v-model="modalShow"
      size="lg"
      hide-footer
      v-if="edit"
    >
      <div class="update">
        <div class="input">
          <label for="">{{ $t("add_user.firstname") }}</label>
          <b-form-input v-model="update.firstName"></b-form-input>
        </div>
        <div class="input">
          <label for=""> {{ $t("add_user.lastname") }} </label>
          <b-form-input v-model="update.lastName"></b-form-input>
        </div>
        <div class="input">
          <label for=""> {{ $t("add_user.fullname") }} </label>
          <b-form-input v-model="update.fullName"></b-form-input>
        </div>
        <div class="input">
          <label for=""> {{ $t("admin.email") }} </label>
          <input type="email" v-model="update.email" />
        </div>
        <div class="input" style="margin-bottom: 20px">
          <label for=""> {{ $t("add_user.role") }} </label>
          <b-form-select
            placeholder="role"
            v-model="update.role"
            :options="userRoles"
          ></b-form-select>
        </div>
        <div class="input">
          <label for=""> {{ $t("admin.password") }} </label>
          <input :type="passtype" v-model="update.password" />
          <i :class="show_pass" @click="showPassword"></i>
        </div>
        <div>
          <label for="">{{ $t("add_user.permission") }}</label>
          <multiselect
            v-model="selected"
            :placeholder="$t('add_user.permission')"
            label="permission"
            track-by="permission"
            :options="allPermission"
            :multiple="true"
            :taggable="true"
          ></multiselect>
        </div>
        <button
          type="submit"
          class="submit mt-3 btn btn-primary"
          @click="UpdateUser()"
        >
          <span v-show="!isLoading">{{ $t("admin.edit") }}</span>
          <span v-show="isLoading">
            {{ $t("login.loading") }}
            <spinner v-show="isLoading" text="loading" />
          </span>
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
import { VueEditor } from "vue2-editor";
import spinner from "../../spinner/Spinner.vue";
import ConfirmDelete from "../../../components/Confirm/DeleteConfirm.vue";
import AddUser from "../AddComponents/AddUser.vue";
import Swal from "sweetalert2";

export default {
  name: "getTeam",
  components: {
    AddUser,
    VueEditor,
    Multiselect: VueMultiselect.default,
    spinner,
    ConfirmDelete,
  },
  data() {
    return {
      modalShow: false,
      allUser: [],
      edit: false,
      active_id: "",
      update: {
        firstName: "",
        lastName: "",
        email: "",
        fullName: "",
        password: null,
        role: "",
      },
      selected: "",
      allPermission: [],
      userRoles: [
        { value: "moderator", text: "moderator" },
        { value: "owner", text: "owner" },
      ],
      passtype: "password",
      show_pass: "far fa-eye-slash",
      isLoading: false,
    };
  },
  mounted() {
    this.getUser();
    this.getPermissions();
  },
  methods: {
    showPassword() {
      if (this.passtype === "password") {
        this.passtype = "text";
        this.show_pass = "far fa-eye";
      } else {
        this.passtype = "password";
        this.show_pass = "far fa-eye-slash";
      }
    },
    getPermissions() {
      axios.get(`${env.host}/get/permissions`).then((res) => {
        this.allPermission = res.data.item;
      });
    },
    getUser() {
      this.isLoading = true;
      axios.get(`${env.host}/get/user`).then((res) => {
        this.isLoading = false;
        this.allUser = res.data.item;
      });
    },
    editUser(id, firstName, lastName, email, fullName, role, password) {
      this.edit = true;
      this.active_id = id;
      this.update.firstName = firstName;
      this.update.lastName = lastName;
      this.update.email = email;
      this.update.fullName = fullName;
      this.update.role = role;
      this.update.password = password;
    },
    UpdateUser() {
      this.isLoading = true;
      axios
        .post(`${env.host}/edit/user/${this.active_id}`, this.update)
        .then(() => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_edit"),
          });
          this.isLoading = false;
          this.modalShow = false;
          setTimeout(() => {
            this.getUser();
          }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    deleteUser(id) {
      this.isLoading = true;
      axios.delete(`${env.host}/delete/user/${id}`).then(() => {
        Swal.fire({
          icon: "success",
          text: this.$t("success.success_delete"),
        });
        this.isLoading = false;
        this.getUser();
      });
    },
  },
};
</script>

<style scoped>
::v-deep .multiselect__option--highlight,
::v-deep .multiselect__option--highlight:after {
  background: #7e5493 !important;
}
.user_management {
  width: 100%;
  overflow: hidden;
}
.testingScroll {
  width: 100% !important;
  /* min-width: 500px; */
  max-width: 1400px;
  min-height: calc(100vh - 160px);

  max-height: calc(100vh - 200px) !important;
  overflow-x: scroll;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7e549374;
  width: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5e168274;
  cursor: pointer;
}

label {
  font-family: mtavruliBOLD;
}
button {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
:v-deep .btn-danger {
  border-radius: 0px !important;
}
.delete {
  background: #f02849;
  border-radius: 0px !important;
}
i {
  color: white;
}
label {
  margin-bottom: 10px;
}
table {
  width: 100%;
  border-spacing: 0;
  border-radius: 0px;
  overflow: hidden;
}
thead {
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
}
th {
  background: #7e5493;
  color: #fff;
}

td:nth-child(1) {
  background: #7e5493;
  color: #fff;
  border-radius: 0px;
}
th,
td {
  padding: 1em;
}
tr,
td {
  display: block;
}
td {
  position: relative;
}
td::before {
  content: attr(data-label);
  position: absolute;
  left: 0;
  padding-left: 1em;
  font-weight: 600;
  font-size: 0.9em;
  text-transform: uppercase;
}
tr {
  margin-bottom: 1.5em;
  border: 1px solid #ddd;
  border-radius: 0px;
  text-align: right;
}
tr:last-of-type {
  margin-bottom: 0;
}
td:nth-child(n + 2):nth-child(odd) {
  background-color: #ddd;
}
@media only screen and (min-width: 768px) {
  thead {
    visibility: visible;
    position: relative;
  }
  th {
    text-align: left;
    text-transform: uppercase;
    font-size: 0.9em;
  }
  tr {
    display: table-row;
    border: none;
    border-radius: 0px;
    text-align: left;
  }
  tr:nth-child(even) {
    background-color: #ddd;
  }
  td {
    display: table-cell;
  }
  td::before {
    content: none;
  }
  td:nth-child(1) {
    background: transparent;
    color: #444;
    border-radius: 0px;
  }
  td:nth-child(n + 2):nth-child(odd) {
    background-color: transparent;
  }
}
input {
  border-radius: 0px;
  width: 100%;
  border: 1px solid #cccccc;
  padding-left: 10px;
  height: 40px;
  margin-bottom: 8px;
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
</style>
